import axios from "axios";

export const getBaseUrl = () => "https://cvas-demo.neospectra.cloud/api";

// This is for non-authenticated requests
export const $http = axios.create({
  baseURL: `${getBaseUrl()}/`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  }
});

export const getForm = component => {
  return component.$refs.form
    ? component.$refs.form
    : getForm(component.$parent);
};

// Recursive function to find parent function and validate the form
export const parentValidate = component => {
  return getForm(component).validate();
};

// Return if a value is a valid (non-empty) ID
export const validId = val => parseInt(val) > 0;

export default {
  getBaseUrl
};
