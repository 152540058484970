import Vue from "vue";
import App from "./App.vue";
// import "./registerServiceWorker";
import http from "./plugins/http";
import router from "./router";
import store from "./store";
import vuetify from "@/plugins/vuetify";
import VueTelInputVuetify from "vue-tel-input-vuetify/lib";
import auth from "./plugins/auth";
import "./plugins/upload";
import Mask from "./plugins/mask";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";
import VueCompositionAPI from "@vue/composition-api";
import * as VueGoogleMaps from "vue2-google-maps";

Vue.config.productionTip = false;

Vue.use(VueCompositionAPI);
Vue.use(Mask);
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyAqEu_VCjPM_5DrZgeHIoRC-pSdy21ZP3o",
    libraries: "places"
  },
  installComponents: true
});
Vue.use(VueTelInputVuetify, {
  vuetify
});

new Vue({
  http,
  router,
  store,
  config: { auth },
  vuetify,
  render: h => h(App)
}).$mount("#app");
