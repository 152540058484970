<template>
  <v-snackbar
    v-model="active"
    :timeout="notifications.snackbar.timeout"
    v-bind="notifications.snackbar.attrs"
  >
    <p class="mb-0 text-body-1">{{ notifications.snackbar.message }}</p>

    <template v-slot:action="{ attrs }">
      <v-btn
        text
        v-bind="attrs"
        @click="clickHandler"
        v-if="notifications.snackbar.route"
      >
        Create Another
      </v-btn>
      <v-btn text v-bind="attrs" @click="active = false" v-else>
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import { mapState } from "vuex";

export default {
  name: "SnackBar",
  props: ["timeout"],
  computed: {
    ...mapFields(["notifications.snackbar.active"]),
    ...mapState(["notifications"])
  },
  methods: {
    clickHandler() {
      this.$router.push(this.notifications.snackbar.route);
      this.active = false;
    }
  }
};
</script>
<style>
.v-sheet.v-snack__wrapper:not(.v-sheet--outlined) {
  position: fixed !important;
  top: 10% !important;
}
</style>
