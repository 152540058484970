export default {
  afterLogin({ dispatch }) {
    // @TODO Error handling? retry functionality?
    return dispatch("initState");
  },
  initState({ dispatch, commit }) {
    commit("setLoading", { message: "Loading Application...", initial: true });
    return Promise.all([
      dispatch("clients/getClients"),
      dispatch("baseData/load")
    ]).then(() => {
      commit("setLoading", {});
      return true;
    });
  },
  /**
   * Clear the state (used for logout)
   */
  clearState({ commit }) {
    commit("registration/initializeState");
  },
  notify(
    { commit },
    { type = "snackbar", message, route, timeout = 5000, attrs = {} }
  ) {
    if (type === "snackbar") {
      commit("activateSnackbar", {
        active: true,
        message,
        route,
        timeout,
        attrs
      });
      return new Promise(resolve => {
        setTimeout(() => resolve(true), timeout);
      });
    }
    return true;
  }
};
