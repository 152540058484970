import Vue from "vue";
import { getField, updateField } from "vuex-map-fields";
import { getPaginated } from "@/utils/api-utils";
import isEmpty from "lodash.isempty";

const emptyDropBoxLocation = () => ({
  id: null,
  country_id: null,
  state_id: null,
  title: "",
  is_active: true
});

export default {
  namespaced: true,
  state: {
    items: [],
    active_shipment: {},
    dropBoxCountries: [],
    dropBoxCountryId: null,
    dropBoxStateId: null,
    dropBoxLocations: [],
    dropBoxLocation: emptyDropBoxLocation()
  },
  getters: {
    getField,
    unshipped(state) {
      return state.items;
    },
    dropBoxStates(state) {
      let states = new Set();
      state.dropBoxLocations.forEach(box => {
        if (box.is_active && box.state) states.add(box.state);
      });
      return Array.from(states).sort((a, b) => a.title.localeCompare(b.title));
    },
    dropBoxLocations(state, getters) {
      if (getters.dropBoxStates.length > 0) {
        if (!state.dropBoxStateId) return [];
        return state.dropBoxLocations
          .filter(
            dropBox =>
              dropBox.is_active && dropBox.state_id === state.dropBoxStateId
          )
          .sort((a, b) => a.title.localeCompare(b.title));
      }
      return state.dropBoxLocations
        .filter(dropBox => dropBox.is_active)
        .sort((a, b) => a.title.localeCompare(b.title));
    }
  },
  mutations: {
    updateField,
    setItems(state, payload) {
      Vue.set(state, "items", payload);
    },
    setDropBoxCountries(state, countries) {
      state.dropBoxCountries = Array.isArray(countries) ? countries : [];
    },
    setDropBoxLocations(state, dropBoxes) {
      state.dropBoxLocations = Array.isArray(dropBoxes) ? dropBoxes : [];
    },
    clearDropBoxLocation(state) {
      state.dropBoxLocation = emptyDropBoxLocation();
    },
    setDropBoxLocation(state, dropBox) {
      Object.assign(state.dropBoxLocation, dropBox);
    }
  },
  actions: {
    getPaginated: getPaginated("/shipments"),
    getUnshipped({ dispatch }, params = {}) {
      let options = {
        sortBy: ["created"],
        sortDesc: [true],
        params: { unshipped: true, ...params }
      };
      return dispatch("getPaginated", options);
    },
    getShipments({ commit, dispatch }) {
      return dispatch("getPaginated", { itemsPerPage: 100 }).then(
        ({ data }) => {
          commit("setItems", data.data);
        }
      );
    },
    getShipment(ctx, id) {
      return Vue.axios
        .get(`/shipments/${id}`)
        .then(({ data }) => {
          if (data.success && data.data) {
            return data.data;
          }
          return null;
        })
        .catch(() => {
          return null;
        });
    },
    createShipment({ rootGetters }, params = {}) {
      if (isEmpty(rootGetters["clients/selectedClient"])) return false;

      let lab_id =
        params.lab_id ?? rootGetters["clients/selectedClient"].lab_id;

      let tracking_number = params.tracking_number
        ? params.tracking_number
        : null;

      let shipment_method_id = params.shipment_method_id
        ? params.shipment_method_id
        : null;
      return Vue.axios
        .post("shipments", {
          client_id: rootGetters["clients/selectedClient"].id,
          lab_id: lab_id,
          samples_in_box: 0,
          tracking_number: tracking_number ? tracking_number : null,
          shipment_method_id: shipment_method_id ? shipment_method_id : null
        })
        .then(({ data }) => {
          return data.success ? data.data : false;
        })
        .catch(e => {
          console.log(e);
          return false;
        });
    },
    editShipment(context, payload) {
      return Vue.axios
        .put(`/shipments/` + payload.id, payload)
        .then(({ data }) => {
          return data.success || false;
        });
    },
    deleteShipment(ctx, id) {
      return Vue.axios
        .delete(`/shipments/${id}`)
        .then(({ data }) => {
          return data.success;
        })
        .catch(() => {
          return false;
        });
    },
    epGetShipment(ctx, { shipment, params }) {
      return Vue.axios
        .get(`shipments/${shipment.id}/shipping`, {
          params
        })
        .then(({ data }) => {
          return data.success ? data.data : false;
        })
        .catch(e => {
          console.error(e);
          return false;
        });
    },
    epUpdateShipment(ctx, { shipment, data }) {
      return Vue.axios
        .post(`shipments/${shipment.id}/shipping`, data)
        .then(({ data }) => {
          return data.success ? data.data : false;
        })
        .catch(e => {
          console.error(e);
          return false;
        });
    },
    getDropBoxCountries({ commit }) {
      commit("setDropBoxCountries", []);
      return Vue.axios
        .get("/drop-box-locations")
        .then(({ data }) => {
          if (data.success && data.data) {
            commit("setDropBoxCountries", data.data);
          }
          return data.success;
        })
        .catch(() => {
          return false;
        });
    },
    getDropBoxLocations({ state, commit }) {
      commit("setDropBoxLocations", []);
      if (!state.dropBoxCountryId) return Promise.resolve(false);
      return Vue.axios
        .get(`/drop-box-locations/?country_id=${state.dropBoxCountryId}`)
        .then(({ data }) => {
          if (data.success && data.data) {
            commit("setDropBoxLocations", data.data);
          }
          return data.success;
        })
        .catch(() => {
          return false;
        });
    },
    getDropBoxLocation({ commit }, id) {
      commit("clearDropBoxLocation");
      return Vue.axios
        .get(`/drop-box-locations/${id}`)
        .then(({ data }) => {
          if (data.success && data.data) {
            commit("setDropBoxLocation", data.data);
          }
          return data.success;
        })
        .catch(() => {
          return false;
        });
    }
  }
};
