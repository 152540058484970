import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import secureRoute from "@/router/utils/secureRoute";
import loadRoute from "@/router/utils/loadRoute";
import unauthenticated from "@/router/unauthenticated";

Vue.use(VueRouter);

/**
 * ABOUT ROUTES
 *
 * Route "name" attribute needs to be unique (that way we can navigate using route name)
 * If you want to override the menu title, add a property `meta.title` with the title you'd like to use
 *
 * For sub-menus, check the "About" route and its components
 */

const routes = [
  ...unauthenticated,
  {
    path: "/denied",
    name: "Account Setup",
    // route level code-splitting
    // this generates a separate chunk (AccountSetup.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "AccountSetup" */ "@/views/Registration/AccountSetup"
      ),
    meta: {
      // menu: true,
      auth: ["new", "denied"]
    }
  },
  {
    path: "/setup/confirm",
    name: "Account Setup Confirmation",
    component: () =>
      import(
        /* webpackChunkName: "AccountConfirm" */ "@/views/Registration/AccountConfirm"
      ),
    meta: {
      auth: ["new"]
    }
  },
  // Redirect to the dashboard
  { path: "/", redirect: "/dashboard" },
  { path: "*", redirect: "/dashboard" },
  {
    path: "/dashboard",
    name: "Home",
    component: Home,
    meta: {
      auth: ["client", "copy_to"],
      menu: true,
      icon: "mdi-view-dashboard",
      title: "Dashboard"
    }
  },
  {
    path: "/my-account",
    name: "My Account",
    component: loadRoute("MyAccount/MyAccount"),
    meta: {
      auth: ["copy_to"],
      menu: true,
      icon: "mdi-account-outline",
      title: "My Account"
    }
  },
  {
    path: "/my-account-info",
    name: "My Account Info",
    component: () =>
      import(
        /* webpackChunkName: "AccountConfirm" */ "@/views/Registration/AccountSetup"
      ),
    meta: {
      auth: ["client"],
      menu: true,
      icon: "mdi-account-outline",
      title: "My Account"
    }
  },
  {
    path: "/copy-to",
    name: "Copy To",
    component: loadRoute("Containers/BaseContainer"),
    meta: {
      menu: true,
      icon: "mdi-account-multiple-plus-outline",
      title: "Report Recipients"
    },

    children: [
      {
        path: "browse",
        name: "CopyToBrose",
        component: loadRoute("CopyTo/Browse"),
        meta: {
          menu: true,
          icon: "mdi-format-list-bulleted",
          title: "Browse"
        }
      },
      {
        path: "add",
        name: "AddCopyTo",
        component: loadRoute("CopyTo/Invite"),
        meta: {
          menu: true,
          icon: "mdi-plus",
          title: "Invite"
        }
      }
    ]
  },
  {
    path: "/accounts",
    name: "Accounts",
    component: loadRoute("Accounts/Container"),
    meta: {
      menu: true,
      icon: "mdi-account-group-outline",
      title: "Accounts"
    },
    children: [
      {
        /**
         * TODO:
         * This nav item should only appear if one of the clients associated with this accounts is a "billing_only"
         * account.
         */
        path: "billing",
        name: "Manage Billing Accounts",
        component: loadRoute("Accounts/ManageBillingAccounts"),
        meta: {
          menu: true,
          icon: "mdi-account-network-outline"
        }
      }
    ]
  },
  // {
  //   path: "/farms",
  //   name: "Farms",
  //   component: loadRoute("Containers/BaseContainer"),
  //   meta: {
  //     menu: true,
  //     icon: "mdi-silo"
  //   },
  //   children: [
  //     {
  //       path: "browse",
  //       name: "FarmsBrowse",
  //       component: loadRoute("Farms/Browse"),
  //       meta: {
  //         menu: true,
  //         icon: "mdi-format-list-bulleted",
  //         title: "Browse"
  //       }
  //     },
  //     {
  //       path: "add",
  //       name: "Add",
  //       component: loadRoute("Farms/Edit"),
  //       meta: {
  //         menu: true,
  //         icon: "mdi-plus",
  //         title: "Add"
  //       }
  //     },
  //     {
  //       path: "edit/:id",
  //       name: "Edit",
  //       component: loadRoute("Farms/Edit"),
  //       meta: {
  //         menu: false
  //       }
  //     },
  //     {
  //       path: "/farms/:id/sources",
  //       name: "FarmsSourcesBrowse",
  //       component: loadRoute("Sources/Browse")
  //     },
  //     {
  //       path: "/farms/:farmId/sources/add",
  //       name: "FarmsSourcesAdd",
  //       component: loadRoute("Sources/Edit")
  //     },
  //     {
  //       path: "/farms/:farmId/sources/edit/:sourceId",
  //       name: "FarmsSourcesEdit",
  //       component: loadRoute("Sources/Edit")
  //     }
  //   ]
  // },
  {
    path: "/farms",
    name: "New Farms",
    component: loadRoute("Containers/BaseContainer"),
    meta: {
      menu: true,
      icon: "mdi-silo"
    },
    children: [
      {
        path: "newbrowse",
        name: "NewFarmsBrowse",
        component: loadRoute("newFarms/Browse"),
        meta: {
          menu: true,
          icon: "mdi-format-list-bulleted",
          title: "Browse"
        }
      },
      {
        path: "new-add",
        name: "New Add",
        component: loadRoute("newFarms/Edit"),
        meta: {
          menu: true,
          icon: "mdi-plus",
          title: "Add"
        }
      },
      {
        path: "new-edit/:id",
        name: "New Edit",
        component: loadRoute("newFarms/Edit"),
        meta: {
          menu: false
        }
      },
      {
        path: "/farms/:id/new-sources",
        name: "FarmsSourcesBrowse",
        component: loadRoute("newSources/Browse")
      },
      {
        path: "/farms/:farmId/sources/new-add",
        name: "FarmsSourcesAdd",
        component: loadRoute("newSources/Edit")
      },
      {
        path: "/farms/:farmId/sources/new-edit/:sourceId",
        name: "FarmsSourcesEdit",
        component: loadRoute("newSources/Edit")
      }
    ]
  },
  // {
  //   path: "/samples",
  //   name: "Samples",
  //   component: loadRoute("Containers/BaseContainer"),
  //   meta: {
  //     menu: true,
  //     icon: "mdi-leaf"
  //   },
  //   children: [
  //     {
  //       path: "browse",
  //       name: "SamplesBrowse",
  //       component: loadRoute("Samples/Browse"),
  //       meta: {
  //         menu: true,
  //         icon: "mdi-format-list-bulleted",
  //         title: "Browse"
  //       }
  //     },
  //     {
  //       path: "sample-wizard/:sampleId?",
  //       name: "Sample Wizard",
  //       component: loadRoute("Samples/Sample"),
  //       meta: {
  //         menu: true,
  //         icon: "mdi-plus",
  //         title: "Sample Wizard"
  //       }
  //     }
  //   ]
  // },
  {
    path: "/samples",
    name: "New Samples",
    component: loadRoute("Containers/BaseContainer"),
    meta: {
      menu: true,
      icon: "mdi-leaf"
    },
    children: [
      {
        path: "newbrowse",
        name: "NewSamplesBrowse",
        component: loadRoute("newSamples/Browse"),
        meta: {
          menu: true,
          icon: "mdi-format-list-bulleted",
          title: "Browse"
        }
      },
      {
        path: "new-sample-wizard/",
        name: "New Sample Wizard",
        component: loadRoute("newSamples/Sample"),
        meta: {
          menu: true,
          icon: "mdi-plus",
          title: "Submit New Sample"
        }
      },
      {
        path: "new-sample-edit/:sampleId",
        name: "New Sample Edit",
        component: loadRoute("newSamples/SampleEdit")
        // meta: {
        //   menu: true,
        //   icon: "mdi-plus",
        //   title: "Sample Wizard"
        // }
      },
      {
        path: "new-sample-images/:sampleId",
        name: "New Sample Images",
        component: loadRoute("newSamples/Images")
      },
      {
        path: "new-sample-view/:sampleId",
        name: "New Sample View",
        component: loadRoute("newSamples/View")
      }
    ]
  },
  // {
  //   path: "/shipments",
  //   name: "Shipments",
  //   component: loadRoute("Containers/BaseContainer"),
  //   meta: {
  //     menu: true,
  //     icon: "mdi-truck"
  //   },
  //   children: [
  //     {
  //       path: "browse",
  //       name: "Browse Shipments",
  //       component: loadRoute("Shipments/Browse"),
  //       meta: {
  //         icon: "mdi-format-list-bulleted",
  //         title: "Browse",
  //         menu: true
  //       }
  //     },
  //     {
  //       path: "add",
  //       name: "Add Shipments",
  //       component: loadRoute("Shipments/Add"),
  //       meta: {
  //         icon: "mdi-plus",
  //         title: "Add Shipment",
  //         menu: true
  //       }
  //     },
  //     {
  //       path: "edit/:shipmentId",
  //       name: "Edit Shipment",
  //       component: loadRoute("Shipments/Edit")
  //     }
  //   ]
  // },
  {
    path: "/reports",
    name: "Reports",
    component: loadRoute("Containers/BaseContainer"),
    meta: {
      menu: true,
      icon: "mdi-folder"
    },
    children: [
      {
        path: "summary",
        name: "Summary Report",
        component: loadRoute("Reports/Summary"),
        meta: {
          icon: "mdi-format-list-bulleted",
          menu: true
        }
      },
      {
        path: "custom-csvs",
        name: "Custom CSV Downloads",
        component: loadRoute("Reports/CustomCsvs/Browse"),
        meta: {
          icon: "mdi-format-list-bulleted",
          menu: true
        }
      },
      {
        path: "custom-csvs/add",
        name: "Add Custom CSV Download",
        component: loadRoute("Reports/CustomCsvs/Edit")
      },
      {
        path: "custom-csvs/edit/:id",
        name: "Edit Custom CSV Download",
        component: loadRoute("Reports/CustomCsvs/Edit")
      }
    ]
  },
  {
    path: "/invoices",
    name: "Invoices",
    component: loadRoute("Containers/BaseContainer"),
    meta: {
      auth: ["client"],
      menu: true,
      icon: "mdi-receipt"
    },
    children: [
      {
        path: "browse",
        name: "Browse Invoices",
        component: loadRoute("Invoices/Browse"),
        meta: {
          icon: "mdi-format-list-bulleted",
          title: "Browse",
          menu: true
        }
      }
    ]
  }
].map(route => secureRoute(route));

Vue.router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default Vue.router;
